"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageHelperTypes = void 0;
const DEFAULT_COOKIE_EXPIRATION = 60 * 24 * 7; // 1 week
exports.StorageHelperTypes = {
    cookie: 'cookie',
    local: 'local',
};
const defaultConfig = {
    type: exports.StorageHelperTypes.cookie,
    expiration: DEFAULT_COOKIE_EXPIRATION,
};
const StorageHelper = (options = defaultConfig) => {
    const { expiration, type } = options;
    const getCookie = (name) => {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ')
                c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    };
    const setCookie = (name, data) => {
        let expires = '';
        if (expiration) {
            const date = new Date();
            date.setTime(date.getTime() + expiration * 60 * 1000);
            expires = `; expires=${date.toUTCString()}`;
        }
        document.cookie = `${name}=${data || ''}${expires}; path=/`;
    };
    const deleteCookie = (name) => {
        document.cookie = `${name}=; Max-Age=-99999999;`;
    };
    const getLocalStorage = (name) => localStorage.getItem(name);
    const setLocalStorage = (name, data) => {
        localStorage.setItem(name, data);
    };
    const deleteLocalStorage = (name) => {
        localStorage.setItem(name, '');
    };
    const isCookie = type === exports.StorageHelperTypes.cookie;
    const getValue = isCookie ? getCookie : getLocalStorage;
    const setValue = isCookie ? setCookie : setLocalStorage;
    const deleteValue = isCookie ? deleteCookie : deleteLocalStorage;
    return {
        setValue,
        getValue,
        deleteValue,
    };
};
exports.default = StorageHelper;
