"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const getHabitStore = (store) => store.habit;
const getHabitJourneys = (store) => {
    const { habitJourneys = {} } = getHabitStore(store);
    return (0, lodash_1.sortBy)(Object.values(habitJourneys), ['order']);
};
exports.default = {
    getHabitJourneys,
};
