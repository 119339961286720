"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.accountHabitNormalizer = exports.habitJourneyNormalizer = void 0;
const habitnest_models_1 = require("@clearsummit/habitnest-models");
const lodash_1 = require("lodash");
const normalizr_1 = require("normalizr");
const accountSegmentschema = new normalizr_1.schema.Entity('accountSegments', {}, {
    idAttribute: 'segment_id',
    processStrategy: (acSegment, parent) => new habitnest_models_1.AccountSegment(Object.assign(Object.assign({}, acSegment), { account_habit: parent.id })),
});
const accountHabitsSchema = new normalizr_1.schema.Entity('accountHabits', {
    segments: [accountSegmentschema],
}, {
    processStrategy: (acHabit) => new habitnest_models_1.AccountHabit(acHabit),
});
const habitJourneySchema = new normalizr_1.schema.Entity('habitJourneys', {}, {
    processStrategy: (hj) => new habitnest_models_1.HabitJourney(hj),
});
const habitJourneyNormalizer = (habitJourneys) => (0, normalizr_1.normalize)(habitJourneys, [habitJourneySchema]).entities;
exports.habitJourneyNormalizer = habitJourneyNormalizer;
const accountHabitNormalizer = (accountHabits) => (0, normalizr_1.normalize)((0, lodash_1.cloneDeep)(accountHabits), [accountHabitsSchema]).entities;
exports.accountHabitNormalizer = accountHabitNormalizer;
