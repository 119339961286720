// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerRed": "rgba(218,20,20,.1)",
	"bannerSuccess": "rgba(90,202,117,.3)",
	"blue": "#1c5898",
	"boxShadowGrey": "rgba(11,9,44,.2)",
	"btnActive": "#0095e2",
	"btnDefault": "#2fb4f9",
	"btnDisabled": "#acb8bc",
	"btnGhostActive": "rgba(0,149,226,.1)",
	"btnGhostDefault": "rgba(0,0,0,0)",
	"btnGhostHover": "rgba(100,193,241,.1)",
	"btnHover": "#64c1f1",
	"btnSecondaryActive": "rgba(0,149,226,.1)",
	"btnSecondaryDefault": "rgba(47,180,249,.1)",
	"btnSecondaryDisabled": "#f4f6f6",
	"btnSecondaryHover": "rgba(100,193,241,.1)",
	"charcoal": "#333",
	"coral": "#ed7f69",
	"darkGrey": "#6b6c7e",
	"error": "#da1414",
	"errorColor": "#da1414",
	"faintBlue": "rgba(28,88,152,.05)",
	"green": "#59bf28",
	"grey": "#acb8bc",
	"lightBlue": "#2fb4f9",
	"lightGrayRuler": "#f4f6f6",
	"lightGrey": "#f1f1f1",
	"lightPineTree": "#e5f3f0",
	"modalOverlay": "rgba(32,33,38,.45)",
	"modalWrapperBackground": "rgba(0,0,0,.2)",
	"olive": "#20cb9a",
	"orange": "#fe7a24",
	"peach": "#fdc",
	"pineTree": "#4e8c8a",
	"primary": "#26b1e2",
	"primaryHover": "#64c1f1",
	"red": "#fa1931",
	"sea": "rgba(47,181,249,.1)",
	"seaPrimary": "#2fb4f9",
	"seaPrimaryWithAlpha": "rgba(47,180,249,.1)",
	"secondary": "#ffa959",
	"silver": "#acb8bc",
	"success": "#70c063",
	"warning": "#ff8f39",
	"white": "#fff",
	"hugeFont": "36px",
	"inputHeight": "38px",
	"l": "24px",
	"lFont": "18px",
	"m": "20px",
	"s": "16px",
	"sFont": "14px",
	"slimButtonHeight": "24px",
	"smallestFont": "10px",
	"smallestSpacer": "5px",
	"spacer": "15px",
	"standardFont": "14px",
	"xl": "28px",
	"xs": "12px",
	"xsFont": "12px",
	"xxl": "32px",
	"xxs": "8px",
	"xxsFont": "10px",
	"xxxl": "40px",
	"xxxs": "4px",
	"xxxxl": "64px",
	"xxxxs": "2px"
};
export default ___CSS_LOADER_EXPORT___;
