"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveAirbridgeWebInfoPayload = exports.authenticateApplePayload = exports.authenticateGooglePayload = exports.authenticateFacebookPayload = exports.removeWalletAddress = exports.addWalletAddress = exports.fetchNftsAddressesPayload = exports.fetchWalletAddressesPayload = exports.fetchHabitJourneysPayload = exports.fetchAccountHabitsPayload = exports.cancelSubscriptionsPayload = exports.createSubscriptionsPayload = exports.createPaymentPortalSession = exports.fetchSubscriptionsPayload = exports.logoutPayload = exports.hydrateUserPayload = exports.signUpPayload = exports.resetPasswordChangePayload = exports.resetPasswordPayload = exports.verifyEmailPostPayload = exports.verifyEmailPayload = exports.loginPayload = void 0;
const services_1 = require("@/helpers/services");
const _1 = require(".");
const loginPayload = ({ email, password }) => ({
    serviceKey: services_1.endpoints.login,
    successActionCreator: _1.ActionCreators.user.loginSuccess.dispatch,
    data: {
        email,
        password,
    },
});
exports.loginPayload = loginPayload;
const verifyEmailPayload = (data) => ({
    serviceKey: services_1.endpoints.verifyEmailGet,
    data,
});
exports.verifyEmailPayload = verifyEmailPayload;
const verifyEmailPostPayload = (data) => ({
    serviceKey: services_1.endpoints.verifyEmailPost,
    data,
});
exports.verifyEmailPostPayload = verifyEmailPostPayload;
const resetPasswordPayload = (data) => ({
    serviceKey: services_1.endpoints.getResetPassword,
    successActionCreator: _1.ActionCreators.user.resetPasswordSuccess.dispatch,
    data,
});
exports.resetPasswordPayload = resetPasswordPayload;
const resetPasswordChangePayload = (data) => ({
    serviceKey: services_1.endpoints.postResetPassword,
    successActionCreator: _1.ActionCreators.user.resetPasswordChangeSuccess.dispatch,
    data,
});
exports.resetPasswordChangePayload = resetPasswordChangePayload;
const signUpPayload = (data) => ({
    serviceKey: services_1.endpoints.signup,
    successActionCreator: _1.ActionCreators.user.signUpSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.signUpFailure.dispatch,
    data,
});
exports.signUpPayload = signUpPayload;
const hydrateUserPayload = () => ({
    serviceKey: services_1.endpoints.me,
    data: null,
    successActionCreator: _1.ActionCreators.user.hydrateUserSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.user.hydrateUserFailure.dispatch,
});
exports.hydrateUserPayload = hydrateUserPayload;
const logoutPayload = () => ({
    serviceKey: services_1.endpoints.logoutUser,
    data: null
});
exports.logoutPayload = logoutPayload;
const fetchSubscriptionsPayload = () => ({
    serviceKey: services_1.endpoints.fetchSubscriptions,
    data: null,
    successActionCreator: _1.ActionCreators.payment.fetchSubscriptionsSuccess.dispatch,
});
exports.fetchSubscriptionsPayload = fetchSubscriptionsPayload;
const createPaymentPortalSession = () => ({
    serviceKey: services_1.endpoints.createPortalSession,
    successActionCreator: _1.ActionCreators.payment.createPaymentPortalSessionSuccess.dispatch,
    errorActionCreator: _1.ActionCreators.payment.createPaymentPortalSessionError.dispatch,
    data: null,
});
exports.createPaymentPortalSession = createPaymentPortalSession;
const createSubscriptionsPayload = (data) => ({
    serviceKey: services_1.endpoints.createSubscription,
    data,
    successActionCreator: _1.ActionCreators.payment.createSubscriptionsSuccess.dispatch,
});
exports.createSubscriptionsPayload = createSubscriptionsPayload;
const cancelSubscriptionsPayload = (data) => ({
    serviceKey: services_1.endpoints.cancelSubscription,
    data,
    successActionCreator: _1.ActionCreators.payment.cancelSubscriptionsSuccess.dispatch,
});
exports.cancelSubscriptionsPayload = cancelSubscriptionsPayload;
const fetchAccountHabitsPayload = () => ({
    serviceKey: services_1.endpoints.fetchAccountHabits,
    data: [],
    successActionCreator: _1.ActionCreators.user.accountHabitSuccess.dispatch,
});
exports.fetchAccountHabitsPayload = fetchAccountHabitsPayload;
const fetchHabitJourneysPayload = () => ({
    serviceKey: services_1.endpoints.fetchHabitJourneys,
    data: [],
    successActionCreator: _1.ActionCreators.habit.fetchHabitJourneysSuccess.dispatch,
});
exports.fetchHabitJourneysPayload = fetchHabitJourneysPayload;
const fetchWalletAddressesPayload = () => ({
    serviceKey: services_1.endpoints.fetchWalletAddresses,
    data: {},
    successActionCreator: _1.ActionCreators.user.fetchWalletAddresses.dispatch,
});
exports.fetchWalletAddressesPayload = fetchWalletAddressesPayload;
const fetchNftsAddressesPayload = () => ({
    serviceKey: services_1.endpoints.fetchNftsAddresses,
    data: {},
    successActionCreator: _1.ActionCreators.user.fetchNftsAddresses.dispatch,
});
exports.fetchNftsAddressesPayload = fetchNftsAddressesPayload;
const addWalletAddress = (data) => ({
    serviceKey: services_1.endpoints.addWalletAddress,
    data,
    successActionCreator: _1.ActionCreators.user.addWalletAddress.dispatch,
    errorActionCreator: _1.ActionCreators.user.addWalletAddress.dispatch,
});
exports.addWalletAddress = addWalletAddress;
const removeWalletAddress = (id) => ({
    serviceKey: services_1.endpoints.removeWalletAddress,
    data: id,
    successActionCreator: _1.ActionCreators.user.removeWalletAddress.dispatch,
});
exports.removeWalletAddress = removeWalletAddress;
const authenticateFacebookPayload = (accessToken) => ({
    serviceKey: services_1.endpoints.authenticateFacebook,
    data: { access_token: accessToken, local_timezone: "" },
    successActionCreator: _1.ActionCreators.user.loginSuccess.dispatch,
    // errorActionCreator: AC.user.authError.dispatch,
});
exports.authenticateFacebookPayload = authenticateFacebookPayload;
const authenticateGooglePayload = (accessToken) => ({
    serviceKey: services_1.endpoints.authenticateGoogle,
    data: { access_token: accessToken, local_timezone: "" },
    successActionCreator: _1.ActionCreators.user.loginSuccess.dispatch,
    // errorActionCreator: AC.user.authError.dispatch,
});
exports.authenticateGooglePayload = authenticateGooglePayload;
const authenticateApplePayload = (data) => ({
    serviceKey: services_1.endpoints.authenticateApple,
    data: Object.assign(Object.assign({}, data), { local_timezone: "" }),
    successActionCreator: _1.ActionCreators.user.loginSuccess.dispatch,
    // errorActionCreator: AC.user.authError.dispatch,
});
exports.authenticateApplePayload = authenticateApplePayload;
const saveAirbridgeWebInfoPayload = (data) => ({
    serviceKey: services_1.endpoints.saveAirbridgeWebInfo,
    data: Object.assign({}, data),
});
exports.saveAirbridgeWebInfoPayload = saveAirbridgeWebInfoPayload;
