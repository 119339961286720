"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noOp = exports.resetStoreKeyClosure = exports.endRequest = exports.startRequest = void 0;
function startRequest(state, payload) {
    return Object.assign(Object.assign(Object.assign({}, state), { pending: true }), payload);
}
exports.startRequest = startRequest;
function endRequest(state, payload) {
    return Object.assign(Object.assign(Object.assign({}, state), { pending: false }), payload);
}
exports.endRequest = endRequest;
const resetStoreKeyClosure = (initialState) => (state, payload) => (Object.assign(Object.assign({}, state), { [payload]: initialState[payload] }));
exports.resetStoreKeyClosure = resetStoreKeyClosure;
const noOp = (state) => (Object.assign({}, state));
exports.noOp = noOp;
const apiReducers = {
    startRequest,
    endRequest,
    noOp: exports.noOp,
};
exports.default = apiReducers;
