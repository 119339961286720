"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUpFieldNames = exports.ResetPasswordChangeFieldNames = exports.ResetPasswordFieldNames = exports.LoginFieldNames = void 0;
var LoginFieldNames;
(function (LoginFieldNames) {
    LoginFieldNames["email"] = "email";
    LoginFieldNames["password"] = "password";
})(LoginFieldNames || (exports.LoginFieldNames = LoginFieldNames = {}));
var ResetPasswordFieldNames;
(function (ResetPasswordFieldNames) {
    ResetPasswordFieldNames["email"] = "email";
})(ResetPasswordFieldNames || (exports.ResetPasswordFieldNames = ResetPasswordFieldNames = {}));
var ResetPasswordChangeFieldNames;
(function (ResetPasswordChangeFieldNames) {
    ResetPasswordChangeFieldNames["password"] = "password";
    ResetPasswordChangeFieldNames["confirmPassword"] = "confirmPassword";
})(ResetPasswordChangeFieldNames || (exports.ResetPasswordChangeFieldNames = ResetPasswordChangeFieldNames = {}));
var SignUpFieldNames;
(function (SignUpFieldNames) {
    SignUpFieldNames["email"] = "email";
    SignUpFieldNames["password"] = "password";
    SignUpFieldNames["firstName"] = "first_name";
    SignUpFieldNames["tos"] = "accepted_tos";
})(SignUpFieldNames || (exports.SignUpFieldNames = SignUpFieldNames = {}));
