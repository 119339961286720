"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.reduxSet = exports.INITIAL_STATE = exports.ACTIONS = void 0;
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const shared_reducers_1 = require("./shared-reducers");
// User Actions
exports.ACTIONS = {
    CLEAR_PAYMENT_PORTAL_SESSION_ERROR: 'PAYMENT/CLEAR_PAYMENT_PORTAL_SESSION_ERROR',
    ADD_PAYMENT_PORTAL_SESSION_ERROR: 'PAYMENT/ADD_PAYMENT_PORTAL_SESSION_ERROR',
    CREATE_PAYMENT_PORTAL_SESSION: 'PAYMENT/CREATE_PAYMENT_PORTAL_SESSION',
    FETCH_SUBSCRIPTIONS_SUCCESS: 'PAYMENT/FETCH_SUBSCRIPTIONS_SUCCESS',
    CREATE_SUBSCRIPTION_SUCCESS: 'PAYMENT/CREATE_SUBSCRIPTION_SUCCESS',
    CANCEL_SUBSCRIPTION_SUCCESS: 'PAYMENT/CANCEL_SUBSCRIPTION_SUCCESS',
};
exports.INITIAL_STATE = {
    subscriptions: [],
    paymentPortalSession: null
};
const fetchSubscriptionsSuccess = (state, payload) => (Object.assign(Object.assign({}, state), { subscriptions: payload.data.data }));
const createPaymentPortalSessionSuccess = (state, payload) => (Object.assign(Object.assign({}, state), { paymentPortalSession: payload.data.data.url }));
const createPaymentPortalSessionError = (state, payload) => (Object.assign(Object.assign({}, state), { paymentPortalSessionError: payload.error.details }));
const clearPaymentPortalSessionError = (state) => (Object.assign(Object.assign({}, state), { paymentPortalSessionError: undefined }));
exports.reduxSet = {
    createPaymentPortalSessionSuccess: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.CREATE_PAYMENT_PORTAL_SESSION, createPaymentPortalSessionSuccess),
    createPaymentPortalSessionError: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.ADD_PAYMENT_PORTAL_SESSION_ERROR, createPaymentPortalSessionError),
    clearPaymentPortalSessionError: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.CLEAR_PAYMENT_PORTAL_SESSION_ERROR, clearPaymentPortalSessionError),
    fetchSubscriptionsSuccess: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.FETCH_SUBSCRIPTIONS_SUCCESS, fetchSubscriptionsSuccess),
    createSubscriptionsSuccess: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.CREATE_SUBSCRIPTION_SUCCESS, shared_reducers_1.noOp),
    cancelSubscriptionsSuccess: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.CANCEL_SUBSCRIPTION_SUCCESS, shared_reducers_1.noOp)
};
const reducer = (state = Object.assign({}, exports.INITIAL_STATE), action) => (0, radio_dispatch_1.runReducers)(state, action, exports.reduxSet);
exports.reducer = reducer;
