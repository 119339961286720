"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const formik_1 = require("formik");
const React = __importStar(require("react"));
const react_redux_1 = require("react-redux");
const { useEffect } = React;
const BaseForm = (props) => {
    const { onSubmitPayload, initialValues, validationSchema, clearError, formKey, children, makeRequest, initialErrors, } = props;
    useEffect(() => () => clearError(formKey), []);
    const onSubmit = (data) => {
        const payload = onSubmitPayload(data);
        makeRequest(payload);
    };
    return (React.createElement(formik_1.Formik, { initialValues: initialValues, onSubmit: onSubmit, validationSchema: validationSchema, validateOnBlur: false, initialErrors: initialErrors },
        React.createElement("form", { onSubmit: onSubmit }, children)));
};
BaseForm.defaultProps = {
    initialErrors: {},
};
const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
    clearError: (key) => dispatch(radio_dispatch_1.reduxSet.clearError.dispatch(key)),
    makeRequest: (payload) => dispatch(radio_dispatch_1.reduxSet.makeRequest.dispatch(payload)),
});
exports.default = (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps)(BaseForm);
