"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.textColor = exports.gradientColor = void 0;
const core_1 = require("@emotion/core");
const gradientColor = (colors) => (0, core_1.css) `
    &:before {
      background: linear-gradient(to left, ${colors[0]}, ${colors[1]});
    }
  `;
exports.gradientColor = gradientColor;
const textColor = (color) => (0, core_1.css) `
  & {
    color: ${color};
  }
`;
exports.textColor = textColor;
