"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    general: {
        email: 'Email',
        anErrorOccurred: 'An error occurred.',
        thereWasAnError: 'There was an error processing your request.',
        password: 'Password',
        close: 'close',
        loading: 'Loading',
        okay: 'Okay',
        enterHere: 'Enter Here',
        next: 'Next',
        back: 'Back',
        home: 'Home',
        cancel: 'Cancel',
        disconnect: 'Disconnect',
        requiredField: (label) => `${label} is a required field`
    },
    home: {
        greetings: 'Greetings',
        niceToSeeYou: (name) => `Nice to see you ${name}`,
        clickToReplaceName: 'Click to replace name',
    },
    login: {
        pleaseEnterAValid: 'Please enter a valid email address.',
        incorrectPassword: 'Incorrect password.',
        welcomeBack: 'Welcome Back',
        enterEmail: 'Enter Email Address',
        enterPassword: 'Enter Password',
        welcome: 'Welcome to Your New Home for Healthy Habits',
        signIn: 'Log In',
        signUp: 'Sign Up',
        logOut: "Log Out",
        description: 'Please log in below to continue.',
        forgotPassword: 'Forgot Password?',
        dontHaveAnAccount: "Don't Have An Account?",
        email: 'Email*',
        password: "Password*"
    },
    signup: {
        pleaseEnterAValidEmail: 'Please enter a valid email address.',
        passwordsDoNotMatch: 'Passwords do not match.',
        beAtLeast8: 'Be at least 8 characters',
        beAtLeast4: 'Be at least 4 characters',
        notExceed50: 'Not exceed 50 characters',
        containAtLeast1Number: 'Contain at least one number',
        containAtLeast1Special: 'Contain at least one special character',
        welcomeToHabitnest: 'Welcome to Your New Home for Healthy Habits',
        createAccount: 'Create your account to get started.',
        signUpToGet: 'Sign up to get started',
        passwordMustBe: 'Password must be 8 characters and contain at least one letter, one number and one special character.',
        confirmPassword: 'Confirm Password',
        getStarted: 'Get Started!',
        inputEmail: 'Input Email Here',
        iAcceptThe: 'I agree to Habit Nest’s No-Nonsense ',
        termsAndConditions: 'Privacy Policy',
        firstName: 'First Name',
        lastName: 'Last Name',
        username: 'username',
        email: 'Email*',
        password: 'Password*',
        signUp: 'Sign Up',
        alreadyHaveAnAccount: 'Already have an account? ',
        login: 'Login',
    },
    resetPassword: {
        title: 'Create a new password',
        description: 'Enter your new password below. Use at least 8 characters. Your can use uppercase, lowercase and symbols.',
        forgotYourPassword: 'Forgot Your Password?',
        pleaseEnterYourEmail: 'Please enter your email below to receive your password reset instructions.',
        ifYouHaveAnAccount: 'If you have an account, a reset link was sent to your email.',
        resetYourPassword: 'Reset Your Password',
        pleaseEnterYourNewPassword: 'Please enter your new password.',
        resetPassword: 'Reset Password',
        passwordChangedSuccessfully: 'Password changed successfully please log in to continue',
        enterNewPassword: 'Enter New Password',
        confirmNewPassword: 'Confirm New Password',
        sendMeInstructions: "Send me the instructions",
        saveNewPassword: 'Save new password',
    },
    changePassword: {
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        changePassword: 'Change Password',
    },
    instructions: {
        title: 'Instructions sent!',
        description: 'If you have a Habit Nest account, you will recieve a reset link with instructions to create a new password.',
        goBackToSignIn: 'Go back to sign in'
    },
    verifyEmail: {
        verifyEmail: 'Verify email address',
        weveSentAnEmailToVerify: `We've sent an email to verify your address.  Please check your email and follow the link.`,
        resendEmail: 'Resend email'
    },
    verifyEmailConfirm: {
        verifyEmailAddress: 'Verify email address',
        thanksforValidatingYourEmail: `Thanks for validating your email.`,
    },
    changePasswordSuccess: {
        changePasswordConfirmation: 'Change Password Confirmation',
        youveHaveSuccessfullyChanged: 'You have successfully changed your password.',
    },
    nav: {
        connectWallet: 'Connect Wallet',
        profile: 'Profile',
        subscriptions: 'Subscriptions',
        paymentPortal: 'Payment Portal',
    },
    profile: {
        title: 'Profile',
        activeHabitJourneys: 'Active Habit Journeys',
        noActiveJourneys: 'You have no active journeys.',
    },
    cancelSubscriptionModal: {
        areYouSure: 'Are you sure you want to cancel your subscription?',
        youWillRetainAccess: 'You will retain access to the app until the end of your subscription period.',
        cancelSubscription: 'Cancel Subscription',
    },
    subscriptions: {
        unlimitedAccess: 'Get Unlimited Access to All Habits',
        freeTrialAndMoneyBackGuaranteed: 'All plans include a <u>7-day risk-free trial.</u> ',
        easilyCancel: 'If you’re unhappy with the app, email support@habitnest.com within 7 days of subscribing, and we will refund you immediately.',
        noQuestionAsked: 'No questions asked.',
        thisWeekOnly: 'This week only, existing members of the Habit Nest family get 30% off!',
        pricingReference: '(For reference, pricing on the App Store & GooglePlay is $99/annually, and 19.99/monthly.)',
        continueToPayment: 'Continue to Payment',
        fullName: "Full Name",
        subscribe: "Subscribe",
        textAgreement: 'Just want to add a personal note from our team before you subscribe: We have poured our hearts and souls into creating this app — we are unbelievably proud of it and know you will LOVE it. A world of new habits (including some new, never-before-seen ones) is one step away ☝️.',
        textAgreementSecond: 'If you choose to subscribe, you have a 1-week risk-free trial, followed by a recurring subscription if you see the value in continuing. We’d be honored to have you in the app family ❤️',
        subHabitnest: 'Subscribe to Habit Nest',
        successMessage: 'The subscription was completed successfully.',
        cancelSuccessMessage: 'The subscription was canceled successfully.',
        successDescription: 'The updated information might take a few moments to appear. ',
        subscriptionPlan: 'Subscription Plan',
        cancelSubscription: 'Cancel Subscription',
        areYouSureCancel: 'Are you sure you want to cancel your subscription?',
        youWillRetain: 'You will retain access to the app until the end of your subscription period.',
        loading: 'Loading...',
        useOriginalCancel: 'Please cancel using your original subscription method.',
        mustCancelVia: 'Must be canceled via Google Play or Apple App Store.',
        youAreSetToGo: 'You’re all set to go!',
        simplyGo: (appStoreLink, googlePlayLink) => `Simply go to the <a href="${appStoreLink}" target="blank">Apple App Store</a> or the <a href="${googlePlayLink}" target="blank">Google Play Store</a>, download The Habit Nest App and log in to get your habit-building journey started 🙂`
    },
    walletConnection: {
        web3Integration: 'Web3 Integration',
        connectingtoWalletOptional: 'Connecting your web3 wallet (such as MetaMask) is a completely optional step.',
        enableToEligibleForBonuses: 'It will enable you to be eligible for bonuses we will add to the app in the future.',
        connectWeb3Wallet: 'Connect Web3 Wallet',
        connectingToWeb3Wallet: 'Connecting to Web3 Wallet...',
        web3WalletConnected: 'Web3 Wallet Connected',
        manageWeb3Integration: 'Manage Web3 Integrations',
        walletNotFound: 'Wallet not found or permission denied.',
        selectYourPreferredWallet: 'Please select your preferred wallet.',
        nftsAvailable: (numberNfts) => `You have ${numberNfts} NFT(s) available.`,
        walletsConnected: (numberWallets) => `${numberWallets} Wallet(s) connected.`,
        disconnectWallet: `Disconnect Wallet`,
        areYouSure: (walletName) => `Are you sure you want to disconnect ${walletName}?`,
        clickToDisconnect: `Click to disconnect`
    },
    validSubscriptionModal: {
        allSet: 'You\'re all set to go!',
        goToTheStore: 'Simply go to the Google Play Store or the Apple App Store, download The Habit Nest App and log in to get your habit-building journey started 🙂',
    },
};
