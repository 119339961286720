"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.reduxSet = exports.updateUser = exports.INITIAL_STATE = exports.ACTIONS = exports.UserWithWallet = void 0;
const habitnest_models_1 = require("@clearsummit/habitnest-models");
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const lodash_1 = require("lodash");
const shared_reducers_1 = require("@/redux/shared-reducers");
class UserWithWallet extends habitnest_models_1.User {
}
exports.UserWithWallet = UserWithWallet;
// User Actions
exports.ACTIONS = {
    START_UP: 'START_UP',
    SET_USER_SESSION: 'SET_USER_SESSION',
    CREATE_USER: 'CREATE_USER',
    FAILURE_CREATE_USER: 'FAILURE_CREATE_USER',
    CLEAR_USER_SESSION: 'CLEAR_USER_SESSION',
    HYDRATE_USER_SUCCESS: 'USER/HYDRATE_USER_SUCCESS',
    HYDRATE_USER_FAILURE: 'USER/HYDRATE_USER_FAILURE',
    STORE_USER: 'STORE_USER',
    LOGOUT: 'LOGOUT',
    HYDRATE_USER: 'HYDRATE_USER',
    START_REQUEST: 'USER/START_REQUEST',
    END_REQUEST: 'USER/END_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USER/RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_CHANGE_SUCCESS: 'USER/RESET_PASSWORD_CHANGE_SUCCESS',
    ACCOUNT_HABITS_SUCCESS: 'USER/ACCOUNT_HABITS_SUCCESS',
    ADD_WALLET_ADDRESS: 'USER/ADD_WALLET_ADDRESS',
    REMOVE_WALLET_ADDRESS: 'USER/REMOVE_WALLET_ADDRESS',
    FETCH_WALLET_ADDRESS: 'USER/FETCH_WALLET_ADDRESS',
    FETCH_NFTS: 'USER/FETCH_NFTS',
};
exports.INITIAL_STATE = {
    user: null,
    pending: false,
    error: null,
    accountHabits: {},
    accountSegments: {},
};
const startUp = (state) => state;
const signUpFailure = (state, payload) => (Object.assign(Object.assign({}, state), { error: payload.details }));
const updateUser = (state, payload) => {
    const { data } = payload.data;
    const habitIDs = (0, lodash_1.uniq)((0, lodash_1.map)(Object.values(data.habits), 'habit_id'));
    return Object.assign(Object.assign({}, state), { user: new UserWithWallet(Object.assign(Object.assign({}, data), { habits: habitIDs })) });
};
exports.updateUser = updateUser;
const storeUser = (state, payload) => {
    const { user } = payload;
    const habitIDs = (0, lodash_1.uniq)((0, lodash_1.map)(Object.values(user.habits), 'habit_id'));
    return Object.assign(Object.assign({}, state), { user: new UserWithWallet(Object.assign(Object.assign({}, user), { habits: habitIDs })) });
};
const logOut = (state) => (Object.assign(Object.assign({}, state), { user: null }));
const resetPasswordSuccess = (state) => (Object.assign({}, state));
const storeRegisteredUser = (state, payload) => (Object.assign(Object.assign({}, state), { user: payload.data }));
const addWallet = (state, payload) => {
    const { user } = state;
    user.wallets = [payload.data.data.wallet];
    return Object.assign(Object.assign({}, state), { user });
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const removeWallet = (state, payload) => {
    const { user } = Object.assign({}, state);
    user.wallets = [];
    return Object.assign(Object.assign({}, state), { user });
};
const addWallets = (state, payload) => {
    const { user } = state;
    user.wallets = payload.data.data;
    return Object.assign(Object.assign({}, state), { user });
};
const addNfts = (state, payload) => {
    const { user } = state;
    user.nfts = payload.data.data;
    return Object.assign(Object.assign({}, state), { user });
};
exports.reduxSet = {
    startUp: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.START_UP, startUp),
    loginSuccess: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.SET_USER_SESSION, shared_reducers_1.noOp),
    signUpSuccess: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.CREATE_USER, storeRegisteredUser),
    hydrateUserSuccess: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.HYDRATE_USER_SUCCESS, exports.updateUser),
    hydrateUserFailure: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.HYDRATE_USER_FAILURE, logOut),
    logOut: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.LOGOUT, logOut),
    signUpFailure: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.FAILURE_CREATE_USER, signUpFailure),
    resetPasswordSuccess: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.RESET_PASSWORD_SUCCESS, resetPasswordSuccess),
    resetPasswordChangeSuccess: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.RESET_PASSWORD_CHANGE_SUCCESS, resetPasswordSuccess),
    dispatchStoreUser: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.STORE_USER, storeUser),
    accountHabitSuccess: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.ACCOUNT_HABITS_SUCCESS, shared_reducers_1.noOp),
    addWalletAddress: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.ADD_WALLET_ADDRESS, addWallet),
    removeWalletAddress: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.REMOVE_WALLET_ADDRESS, removeWallet),
    fetchWalletAddresses: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.FETCH_WALLET_ADDRESS, addWallets),
    fetchNftsAddresses: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.FETCH_NFTS, addNfts),
};
const reducer = (state = Object.assign({}, exports.INITIAL_STATE), action) => (0, radio_dispatch_1.runReducers)(state, action, exports.reduxSet);
exports.reducer = reducer;
