"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
class BaseModel {
    constructor(data = {
        id: null,
    }) {
        this.id = data.id || '';
    }
    get valid() {
        return !!this.id;
    }
    clone() {
        return (0, lodash_1.cloneDeep)(this);
    }
    toObject() {
        const obj = {};
        const clone = this.clone();
        for (const property in clone) { // eslint-disable-line no-restricted-syntax
            if (property.indexOf('_') === -1) {
                // Hide snakecased and private properties
                // @ts-ignore
                obj[property] = clone[property];
            }
        }
        return obj;
    }
    fromCamelObject(obj) {
        Object.assign(this, obj);
    }
}
exports.default = BaseModel;
