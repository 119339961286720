"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.reduxSet = exports.INITIAL_STATE = exports.ACTIONS = void 0;
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const schema_1 = require("./schema");
exports.ACTIONS = {
    FETCH_HABIT_JOURNEYS_SUCCESS: 'HABIT/FETCH_HABIT_JOURNEYS_SUCCESS',
    FETCH_HABITS_SUCCESS: 'HABIT/FETCH_HABITS_SUCCESS',
    FETCH_HABIT_PHASES_SUCCESS: 'HABIT/FETCH_HABIT_PHASES_SUCCESS',
    FETCH_SEGMENT_PAGES_SUCCESS: 'HABIT/FETCH_SEGMENT_PAGES_SUCCESS',
    SAVE_SEGMENT_ANSWERS_SUCCESS: 'HABIT/SAVE_SEGMENT_ANSWERS_SUCCESS',
    UPDATE_OVERVIEW_INDEX: 'HABIT/UPDATE_OVERVIEW_INDEX',
    INCREASE_OVERVIEW_INDEX: 'HABIT/INCREASE_OVERVIEW_INDEX',
    SAVE_OVERVIEW_ANSWERS: 'HABIT/SAVE_OVERVIEW_ANSWERS',
};
exports.INITIAL_STATE = {
    habitJourneys: {},
};
const fetchHabitJourneysSuccess = (state, payload) => {
    const { data } = payload.data;
    const journeys = data;
    const { habitJourneys = {} } = (0, schema_1.habitJourneyNormalizer)(journeys);
    return Object.assign(Object.assign({}, state), { habitJourneys });
};
exports.reduxSet = {
    fetchHabitJourneysSuccess: (0, radio_dispatch_1.dispatchReducer)(exports.ACTIONS.FETCH_HABIT_JOURNEYS_SUCCESS, fetchHabitJourneysSuccess),
};
const reducer = (state = Object.assign({}, exports.INITIAL_STATE), action) => (0, radio_dispatch_1.runReducers)(state, action, exports.reduxSet);
exports.reducer = reducer;
