"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteAuth = exports.getAuth = exports.setAuth = void 0;
const carabiners_1 = require("@clearsummit/carabiners");
const constants_1 = require("@/constants");
const LocalStorageService = (0, carabiners_1.StorageHelper)({ type: constants_1.StorageHelperTypes.local });
const { getValue, setValue, deleteValue } = LocalStorageService;
const setAuth = (data) => setValue(constants_1.Cookies.auth, JSON.stringify(data));
exports.setAuth = setAuth;
const getAuth = () => {
    const token = getValue(constants_1.Cookies.auth);
    if (token) {
        return JSON.parse(token);
    }
    return null;
};
exports.getAuth = getAuth;
const deleteAuth = () => deleteValue(constants_1.Cookies.auth);
exports.deleteAuth = deleteAuth;
exports.default = {};
