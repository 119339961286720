"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("./constants");
const eventManager = {
    events: new Map(),
    rendered: false,
    mounted: false,
    register(action, callback) {
        this.events.set(action, callback);
        return this;
    },
    clear(action) {
        this.events.delete(action);
        return this;
    },
    emit(action, type, name) {
        if (action === constants_1.ModalActions.RENDER_MODAL) {
            this.rendered = true;
        }
        else if (action === constants_1.ModalActions.REMOVE_MODAL) {
            this.rendered = false;
        }
        const fn = this.events.get(action);
        if (fn) {
            fn.call(null, type, name);
        }
        return this;
    },
};
exports.default = eventManager;
