"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@emotion/core");
const Styles = {
    isViewingMobileNav: (open) => (0, core_1.css) `
    ${open && `
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    `}
  `,
};
exports.default = Styles;
