"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@emotion/core");
const Styles = {
    navOpen: (open) => (0, core_1.css) `
    ${open && `
      @media (max-width: 600px) {
        display: flex;
        margin-left: 0;
      }
    `}
  `,
    contentNavMode: (navOpen) => (0, core_1.css) `
    ${navOpen && `
      @media (max-width: 600px) {
        display: none;
      }
    `}
  `,
};
exports.default = Styles;
